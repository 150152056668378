import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'
import classnames from 'classnames'

import { ReleaseContributorsFragment } from '../../api/types'
import { Section } from '../../lib/components/section'
import { Link } from '../../lib/components/link'
import { intersperse } from '../../lib/intersperse'
import css from './styles.css'

export type Contributor = ReleaseContributorsFragment['contributors'][0]
type Props = ReleaseContributorsFragment

export function ReleaseContributors(props: Props): ReactElement {
    const { contributors } = props
    return (
        <Section title={<Trans>Contributors</Trans>} id='release-contributors'>
            {intersperse(
                ', ',
                contributors
                    .map(function (contributor: Contributor): ReactElement | null {
                        if (!contributor) {
                            // Can the API guarantee that the contributor exists
                            return null
                        }

                        const {
                            user: { username },
                            isOriginalSubmitter,
                        } = contributor

                        // TODO: Slugify username
                        const url = `/user/${username}`
                        const className = classnames(isOriginalSubmitter && css.original)

                        return (
                            <Link href={url} className={className} key={username}>
                                {username}
                            </Link>
                        )
                    })
                    .filter((el: ReactElement | null): boolean => el !== null),
            )}
        </Section>
    )
}
