import { ReactElement, Fragment } from 'react'

import { intersperse } from '../../lib/intersperse'
import { Link, Typography } from '@mui/material'

import { LabelRelationship } from '../release-label-link'

type Series = LabelRelationship

type SeriesLinkProps = {
    series: Series[]
}

export function ReleaseSeriesLink(props: SeriesLinkProps): ReactElement | null {
    const { series } = props

    return (
        <>
            {intersperse(
                ', ',
                series.map(function (serie: Series, index: number): ReactElement | null {
                    const { label, catalogNumber, displayName } = serie
                    const name = displayName ?? label.name
                    const href = 'siteUrl' in label && label.siteUrl
                    const link = href ? (
                        <Link variant='labelSmall' href={href}>
                            {name}
                        </Link>
                    ) : (
                        name
                    )

                    return (
                        <Fragment key={'discogsId' in label ? label.discogsId : name}>
                            {link}
                            {catalogNumber && <Typography variant='labelSmall'>{` – ${catalogNumber}`}</Typography>}
                        </Fragment>
                    )
                }),
            )}
        </>
    )
}

ReleaseSeriesLink.defaultProps = {
    series: [],
}
