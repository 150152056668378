import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'
import { Markup } from '../../lib/components/markup'

import { UserCollectionWantlistInfoFragment, NoteFieldType } from '../../api/types'

import { Box, Field } from './box'
import { useEditWantlistItemNotesMutation } from './mutations'

import css from './wantlist-item.css'

type WantlistItem = NonNullable<NonNullable<UserCollectionWantlistInfoFragment>['inWantlist']>

type Props = {
    discogsId: number
    item: WantlistItem
    remove: () => void
    removing: boolean
}

export function WantlistItemUI(props: Props): ReactElement {
    const { discogsId, item, remove, removing } = props
    const { addedAt, notes } = item

    const edit = useEditWantlistItemNotesMutation({ discogsId, notes: notes?.markup ?? '' })

    function handleNotesChange(notes: string): void {
        void edit.perform(notes)
    }
    return (
        <Box
            title={<Trans>In Wantlist</Trans>}
            added={new Date(addedAt)}
            className={css.wantlist}
            onRemove={remove}
            removing={removing}
        >
            <Field
                name={<Trans>Notes</Trans>}
                value={notes?.markup ?? ''}
                onChange={handleNotesChange}
                type={NoteFieldType.Textarea}
                render={<Markup html={notes?.html} />}
            />
        </Box>
    )
}
