/* eslint-disable @typescript-eslint/no-non-null-assertion */
// Adjustments to the graphql schema to allow aggregate counts of
// releases in users collection and wantlist created issues in this file
// artist beta deadline required the non-null workaround
// return to correctly type this.

import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'
import { useApolloClient } from '@apollo/client'
import { Markup } from '../../lib/components/markup'

import { useRemoveReleaseFromCollectionMutation } from '../../mutations/remove-release-from-collection'
import {
    NoteFieldType,
    UserCollectionWantlistInfoFragment,
    UserCollectionSettingsFragment,
    UserCollectionFoldersDocument,
    UserCollectionFoldersQuery,
} from '../../api/types'

import { Box, Field, Option } from './box'

import {
    useEditCollectionItemNoteMutation,
    useRemoveCollectionItemNoteMutation,
    useMoveCollectionItemMutation,
} from './mutations'

import css from './collection-item.css'

type Props = {
    discogsId: number
    user: UserCollectionSettingsFragment
    item: CollectionItem
}

type CollectionItem = NonNullable<
    NonNullable<NonNullable<UserCollectionWantlistInfoFragment['collectionItems']>['edges'][number]>['node']
>
type CollectionNoteTypeEdge = NonNullable<
    NonNullable<UserCollectionSettingsFragment['collectionNoteTypes']>['edges']
>[number]
type CollectionNoteTypeNode = NonNullable<
    NonNullable<UserCollectionSettingsFragment['collectionNoteTypes']>['edges']
>[number]['node']
type CollectionFolderEdge = NonNullable<
    NonNullable<NonNullable<NonNullable<UserCollectionFoldersQuery['viewer']>['collectionFolders']>['edges']>[number]
>
type CollectionFolderNode = NonNullable<
    NonNullable<
        NonNullable<NonNullable<UserCollectionFoldersQuery['viewer']>['collectionFolders']>['edges']
    >[number]['node']
>

type CollectionNote = NonNullable<CollectionItem['notes']>[number]

export function CollectionItemUI(props: Props): ReactElement {
    const { discogsId, item, user } = props
    const { discogsId: itemId, addedAt, folder } = item

    const remove = useRemoveReleaseFromCollectionMutation()
    const edit = useEditCollectionItemNoteMutation({ itemId })
    const removeNote = useRemoveCollectionItemNoteMutation({ itemId })
    const move = useMoveCollectionItemMutation({ itemId })
    const client = useApolloClient()

    function removeFromCollection(): void {
        void remove.perform({ discogsId, itemId })
    }

    function editNote(discogsTypeId: number, value: string): void {
        const note = item.notes?.find((note: CollectionNote): boolean => note.noteType.discogsId === discogsTypeId)

        if (value === '') {
            if (note) {
                void removeNote.perform(note.discogsId)
            }
            return
        }

        void edit.perform(discogsTypeId, note?.discogsId, value)
    }

    function editFolder(value: string, name: string): void {
        const discogsId = parseInt(value, 10)
        void move.perform(discogsId, name)
    }

    const fields = user.collectionNoteTypes
        ?.edges!.map((edge: CollectionNoteTypeEdge): CollectionNoteTypeNode | undefined => edge.node)
        .filter((node: CollectionNoteTypeNode | undefined): node is CollectionNoteTypeNode => Boolean(node))
        .sort(function (a: CollectionNoteTypeNode, b: CollectionNoteTypeNode): number {
            return (a?.displayOrder ?? 99999) - (b?.displayOrder ?? 99999)
        })
        .map(function (node: CollectionNoteTypeNode): ReactElement | null {
            if (!node) {
                return null
            }
            const { name, options, fieldType, discogsId: discogsTypeId } = node
            const note = item.notes?.find((note: CollectionNote): boolean => note.noteType.discogsId === discogsTypeId)

            return (
                <Field
                    key={discogsTypeId}
                    name={name}
                    options={options?.map((opt: string): Option => ({ name: opt, value: opt }))}
                    value={note?.text?.markup ?? ''}
                    onChange={(value: string): void => editNote(discogsTypeId, value)}
                    type={fieldType}
                    render={<Markup html={note?.text?.html} />}
                />
            )
        })

    async function loadFolders(): Promise<Option[]> {
        /* eslint-disable prefer-destructuring */
        try {
            let edges = user.collectionFolders?.edges
            if (user.collectionFolders?.totalCount! > user.collectionFolders?.edges?.length!) {
                const res = await client.query({
                    query: UserCollectionFoldersDocument,
                })
                edges = res.data.viewer.collectionFolders.edges
            }

            return edges!
                .map((edge: CollectionFolderEdge): CollectionFolderNode | undefined => edge.node)
                .filter((node: CollectionFolderNode | undefined): node is CollectionFolderNode => Boolean(node))
                .map((node: CollectionFolderNode): { name: string; value: string } => ({
                    name: node.name,
                    value: node.discogsId.toString(),
                }))
        } catch (err) {
            return []
        }
    }

    const uncategorized = {
        name: 'Uncategorized',
        value: '1',
    }

    const folderField = user.collectionFolders?.totalCount! >= 1 && (
        <Field
            name={<Trans>Folder</Trans>}
            value={folder?.discogsId ? { name: folder.name, value: folder.discogsId.toString() } : uncategorized}
            loadOptions={loadFolders}
            type={NoteFieldType.Dropdown}
            onChange={editFolder}
            noEmpty
            sort={sortFolders}
        />
    )

    return (
        <Box
            title={<Trans>In Collection</Trans>}
            added={new Date(addedAt)}
            className={css.collection}
            onRemove={removeFromCollection}
            removing={remove.loading}
        >
            {fields}
            {folderField}
        </Box>
    )
}

function sortFolders(a: Option, b: Option): number {
    if (a.name === 'Uncategorized') {
        return -1
    }
    if (b.name === 'Uncategorized') {
        return 1
    }
    if (a.name === b.name) {
        return 0
    }
    return a.name < b.name ? -1 : 1
}
