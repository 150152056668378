import { ReactElement } from 'react'

import { Videos } from '../videos'
import { ReleaseVideosFragment, VideoInfoFragment as Video } from '../../api/types'

type Props = ReleaseVideosFragment

export function ReleaseVideos(props: Props): ReactElement {
    const { discogsId } = props
    return <Videos videos={mergeVideos(props)} discogsId={discogsId} editUrl={`/release/${discogsId}-/videos/update`} />
}

export function mergeVideos(props: Props): Video[] {
    const { videos, masterRelease } = props

    if (masterRelease?.videos && masterRelease.videos.length > 0) {
        return masterRelease.videos
    }

    return videos
}
