import { ReactElement, Ref, useState, useEffect } from 'react'
import { Location } from 'history'
import { Meta } from 'react-head'
import { Route, match } from 'react-router'
import { ApolloError } from '@apollo/client'
import { Trans } from '@lingui/macro'
import loadable from '@loadable/component'
import useIntersectionObserver from '@react-hook/intersection-observer'

import { useFlag } from '../../lib/flags'
import { useLogin } from '../../lib/login'
import { Audio } from '../../lib/components/audio'
import { Content, createOrder } from '../../lib/components/content'
import { PageSpinner } from '../../lib/components/page-spinner'
import { languages, Language } from '../../lib/i18n'
import { useCurrency } from '../../lib/currency'
import { useNavShortcut } from '../../lib/components/shortcuts'
import { AdTop, AdFlexA, AdRight, AdRightB, AdBottom, AdVideo } from '../../lib/components/ads'
import { ErrorMessage } from '../../lib/components/error'
import { Identifier, IdentifierType } from '../../lib/components/identifier'
import { useDiscogsId } from '../../lib/use-discogs-id'
import { Split } from '../../lib/components/sidebar-split'
import { a, i } from '../../lib/keycodes'

import { ReleaseAdTargeting } from '../../components/release-ad-targeting'
import { ReleaseHeader } from '../../components/release-header'
import { ReleaseCredits } from '../../components/release-credits'
import { ReleaseNotes } from '../../components/release-notes'
import { ReleaseCompanies } from '../../components/release-companies'
import { ReleaseTracklist } from '../../components/release-tracklist'
import { ReleaseBarcodes } from '../../components/release-barcodes'
import { ReleaseVersionTable } from '../../components/release-version-table'
import { CuratedLists } from '../../components/curated-lists'
import { ReleaseActions } from '../../components/release-actions'
import { ReleaseStats } from '../../components/release-stats'
import { MarketplaceStats } from '../../components/marketplace-stats'
import { ReleaseCollectionActions } from '../../components/collection-actions'
import { ReleaseVideos } from '../../components/release-videos'
import { ReleaseRatings } from '../../components/ratings'
import { ShareButton } from '../../components/share'
import { ReleaseTitle } from '../../components/release-title'
import { ReleaseMetaTags } from '../../components/release-meta-tags'
import { ReleaseRecommendations } from '../../components/recommendations'
import { ReleaseContributors } from '../../components/contributors'
import { ReleaseReviews } from '../../components/reviews'
import { ReleaseSEOSchema } from '../../components/seo-schema'
import { AlternateLinks } from '../../lib/i18n/alt'
import { ReportAbuse } from '../../components/report-abuse'
import { ErrorSection } from '../../lib/components/error-section'

import { Page } from './analytics'

import {
    useDeferredReleaseDataQuery,
    useAllReleaseDataQuery,
    useInitialReleaseDataQuery,
    useReleaseMarketplaceDataQuery,
    useUserReleaseDataQuery,
    DeferredReleaseDataQuery,
    InitialReleaseDataQuery,
    ReleaseMarketplaceDataQuery,
    UserReleaseDataQuery,
    ItemType,
} from '../../api/types'
import { shouldDisplayAds } from '../../lib/showAds'

const ImageGallery = loadable(() => import('./images'))

const { Main, Sidebar } = createOrder([
    'ad',
    'header',
    'actions',
    'marketplace',
    'collections',
    'stats',
    'ratings',
    'share',
    'ad-flexa',
    'tracklist',
    'ad-video',
    'credits',
    'ad-right',
    'companies',
    'barcodes',
    'notes',
    'versions',
    'recommendations',
    'audio',
    'videos',
    'reviews',
    'lists',
    'contributors',
    'abuse',
    'optout',
])

type Props = {
    location: Location
    match: match<{
        locale?: Language
        discogsId: string
        slug?: string
    }>
}

type QueryResult<T> = {
    data: T | undefined
    loading: boolean
    error?: ApolloError
}

type ReleaseData = {
    initial: QueryResult<InitialReleaseDataQuery>
    deferred: QueryResult<DeferredReleaseDataQuery>
}

function useReleaseData(discogsId: number, deferredInView: boolean): ReleaseData {
    const variables = { discogsId }

    const ssrDefer = useFlag('ssr_defer')
    const fetchPolicy = process.browser && !process.isDevWatching ? 'cache-only' : 'cache-first'

    const all = useAllReleaseDataQuery({
        skip: !ssrDefer,
        ssr: true,
        fetchPolicy,
        errorPolicy: 'all',
        variables,
    })

    const initial = useInitialReleaseDataQuery({
        skip: ssrDefer,
        ssr: true,
        fetchPolicy,
        errorPolicy: 'all',
        variables,
    })

    const deferred = useDeferredReleaseDataQuery({
        skip: ssrDefer || !deferredInView,
        ssr: false,
        returnPartialData: true,
        errorPolicy: 'all',
        variables,
    })

    if (!deferredInView) {
        deferred.loading = true
    }

    if (ssrDefer) {
        return {
            initial: all,
            deferred: all,
        }
    }

    return {
        initial,
        deferred,
    }
}

function useMarketplaceData(discogsId: number): QueryResult<ReleaseMarketplaceDataQuery> {
    const currency = useCurrency()

    return useReleaseMarketplaceDataQuery({
        ssr: true,
        variables: {
            discogsId,
            currency,
        },
    })
}

function useUserReleaseData(discogsId: number): QueryResult<UserReleaseDataQuery> {
    const ssrUser = useFlag('ssr_user')
    const { loggedIn } = useLogin()

    const res = useUserReleaseDataQuery({
        ssr: ssrUser,
        returnPartialData: true,
        errorPolicy: 'all',
        variables: { discogsId },
        skip: !loggedIn,
    })

    if (!loggedIn) {
        return {
            loading: false,
            error: undefined,
            data: {
                viewer: undefined,
                release: undefined,
            },
        }
    }

    return res
}

type AsyncRefs = {
    versions: Ref<HTMLDivElement>
    lists: Ref<HTMLDivElement>
    recommendations: Ref<HTMLDivElement>
    reviews: Ref<HTMLDivElement>
}

function useIsInView(): [boolean, (el: HTMLElement | null) => void] {
    const [el, setEl] = useState<HTMLElement | null>(null)
    const isInView = useIntersectionObserver(el).isIntersecting
    return [isInView, setEl]
}

function useWasTrue(value: boolean): boolean {
    const [wasTrue, setWasTrue] = useState(value)

    useEffect(
        function () {
            if (value) {
                setWasTrue(true)
            }
        },
        [value],
    )

    return wasTrue
}

function useAsyncSectionsInView(): [boolean, AsyncRefs] {
    const versions = useIsInView()
    const lists = useIsInView()
    const recommendations = useIsInView()
    const reviews = useIsInView()

    const refs = {
        versions: versions[1],
        lists: lists[1],
        recommendations: recommendations[1],
        reviews: reviews[1],
    }

    const isAnyInView = versions[0] || lists[0] || recommendations[0] || reviews[0]
    const wasAnyInView = useWasTrue(isAnyInView)

    return [wasAnyInView, refs]
}

export default function ReleasePage(props: Props): ReactElement {
    const { match } = props
    const discogsId = useDiscogsId(match.params.discogsId)

    return (
        <Page discogsId={discogsId}>
            <ReleaseMain {...props} discogsId={discogsId} />
            <Route path={`${match.path}/image/:imageId`} component={ImageGallery} />
        </Page>
    )
}

type MainProps = {
    discogsId: number
}

export function ReleaseMain(props: MainProps): ReactElement {
    const { discogsId } = props

    const [asyncSectionIsInView, refs] = useAsyncSectionsInView()

    const { initial, deferred } = useReleaseData(discogsId, asyncSectionIsInView)
    const userData = useUserReleaseData(discogsId)
    const marketplaceData = useMarketplaceData(discogsId)
    const { loggedIn } = useLogin()
    const { data, error, loading } = initial

    useNavShortcut([a, i], <Trans>Add To Inventory</Trans>, `/sell/post/${discogsId}`)

    if (error?.networkError) {
        throw error.networkError
    }

    if (!data && !loading && !error) {
        // Detect a MissingFieldError caused by a bad type merge and
        // disambiguate it from regular Not Found.
        //
        // The ReleaseData query happens with a fetchPolicy of 'cache-only'
        // on the client.  This means that if there is an error in the
        // cache (a missing field on a nested type, for example), the
        // Apollo client will return no data and no error.
        // Explicitly throw here so the error gets logged.
        throw new Error('MissingFieldsError')
    }

    const doesNotExist = !loading && data && !data.release
    if (doesNotExist) {
        return (
            <ErrorMessage status={404} message={<Trans>That release does not exist or may have been deleted.</Trans>} />
        )
    }

    if (loading || !data || !data.release) {
        return <PageSpinner />
    }

    const { isOffensive, siteUrl, blockedFromSale } = data.release
    const canonical = `https://www.discogs.com/${siteUrl}`

    const noIndexList = [825645]

    const displayAds = shouldDisplayAds(isOffensive, blockedFromSale)

    return (
        <>
            <Identifier type={IdentifierType.Release} discogsId={discogsId} />
            <ReleaseTitle {...data.release} itemType={ItemType.Release} />
            <ReleaseSEOSchema {...data.release} />
            <ReleaseMetaTags {...data.release} itemType={ItemType.Release} />
            <AlternateLinks languages={languages} host='https://www.discogs.com' />
            {noIndexList.includes(discogsId) && <Meta name='robots' content='noindex' />}
            <Meta name='viewport' content='width=device-width, initial-scale=1' />
            <ReleaseAdTargeting itemType={ItemType.Release} {...data.release} />
            <Content>
                <Main name='ad'>
                    {displayAds && (
                        <ErrorSection>
                            <AdTop disableAdsScript={!displayAds} />
                        </ErrorSection>
                    )}
                </Main>

                {/* MAIN */}
                <Main name='header'>
                    <ErrorSection>
                        <ReleaseHeader {...data.release} />
                    </ErrorSection>
                </Main>
                <Main name='tracklist'>
                    <ErrorSection>
                        <ReleaseTracklist {...data.release} />
                    </ErrorSection>
                </Main>
                <Main name='ad-video'>
                    <ErrorSection>
                        <AdVideo disableAdsScript={!displayAds} />
                    </ErrorSection>
                </Main>
                <Main name='companies'>
                    <ErrorSection>
                        <ReleaseCompanies {...data.release} />
                    </ErrorSection>
                </Main>
                <Main name='credits'>
                    <ErrorSection>
                        <ReleaseCredits {...data.release} />
                    </ErrorSection>
                </Main>
                <Main name='notes'>
                    <ErrorSection>
                        <ReleaseNotes {...data.release} />
                    </ErrorSection>
                </Main>
                <Main name='barcodes'>
                    <ErrorSection>
                        <ReleaseBarcodes {...data.release} />
                    </ErrorSection>
                </Main>
                <Main name='versions' ref={refs.versions}>
                    <ErrorSection>
                        <ReleaseVersionTable data={deferred.loading ? null : deferred.data?.release} />
                    </ErrorSection>
                </Main>
                <Main name='recommendations' ref={refs.recommendations}>
                    <ErrorSection>
                        <ReleaseRecommendations discogsId={discogsId} {...deferred.data?.release} />
                    </ErrorSection>
                </Main>
                <Main name='reviews' ref={refs.reviews}>
                    <ErrorSection>
                        <ReleaseReviews itemType={ItemType.Release} discogsId={discogsId} {...deferred.data?.release} />
                    </ErrorSection>
                </Main>

                {/* SIDEBAR */}
                <Sidebar name='actions'>
                    <ReleaseActions {...data.release} />
                </Sidebar>
                <Sidebar name='marketplace'>
                    <ErrorSection>
                        {marketplaceData.data?.release && (
                            <MarketplaceStats
                                {...marketplaceData.data.release}
                                {...userData.data?.release}
                                itemType={ItemType.Release}
                            />
                        )}
                    </ErrorSection>
                </Sidebar>
                <Sidebar name='stats'>
                    <ErrorSection>
                        {!isOffensive && <ReleaseStats discogsId={discogsId} {...marketplaceData} />}
                    </ErrorSection>
                </Sidebar>
                <Sidebar name='ratings'>
                    <ErrorSection>
                        <Split
                            left={
                                !isOffensive && (
                                    <ReleaseRatings discogsId={discogsId} {...userData.data?.release} addPadding />
                                )
                            }
                            right={!isOffensive && <ShareButton url={canonical} title={data.release.title} />}
                        />
                    </ErrorSection>
                </Sidebar>
                <Sidebar name='collections'>
                    <ErrorSection>
                        <br />
                        <ReleaseCollectionActions
                            discogsId={discogsId}
                            info={userData.data?.release}
                            user={userData.data?.viewer}
                            loading={userData.loading}
                        />
                    </ErrorSection>
                </Sidebar>
                <Sidebar name='ad-flexa'>
                    {displayAds && (
                        <ErrorSection>
                            <AdFlexA disableAdsScript={!displayAds} />
                        </ErrorSection>
                    )}
                </Sidebar>
                {loggedIn && (
                    <Sidebar name='audio'>
                        <ErrorSection>
                            <Audio
                                streamingService={deferred.data?.release?.streamingService}
                                height='480px'
                                width='100%'
                            />
                        </ErrorSection>
                    </Sidebar>
                )}
                <Sidebar name='videos'>
                    <ErrorSection>
                        <ReleaseVideos {...data.release} />
                    </ErrorSection>
                </Sidebar>
                <Sidebar ref={refs.lists} name='lists'>
                    <ErrorSection>
                        <CuratedLists
                            {...deferred.data?.release}
                            discogsId={discogsId}
                            user={userData.data?.viewer}
                            itemType={ItemType.Release}
                        />
                    </ErrorSection>
                </Sidebar>
                <Sidebar>
                    {displayAds && (
                        <ErrorSection>
                            <AdRightB disableAdsScript={!displayAds} />
                        </ErrorSection>
                    )}
                </Sidebar>
                <Sidebar name='contributors'>
                    <ErrorSection>
                        <ReleaseContributors {...data.release} />
                    </ErrorSection>
                </Sidebar>
                <Sidebar name='abuse'>
                    <ReportAbuse discogsId={discogsId} itemType={ItemType.Release} />
                </Sidebar>
                <Sidebar name='ad-right'>
                    {displayAds && (
                        <ErrorSection>
                            <AdRight disableAdsScript={!displayAds} />
                        </ErrorSection>
                    )}
                </Sidebar>
            </Content>
            {displayAds && (
                <ErrorSection>
                    <AdBottom disableAdsScript={!displayAds} />
                </ErrorSection>
            )}
        </>
    )
}
