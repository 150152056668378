import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'

import { Section } from '../../lib/components/section'
import { ReleaseActionsFragment } from '../../api/types'
import { Link } from '../../lib/components/link'
import { useNavShortcut } from '../../lib/components/shortcuts'
import { ShortCode } from '../../lib/components/shortcode'
import { IdentifierType } from '../../lib/components/identifier'
import { useInteraction } from '../../lib/components/analytics'
import { g, e } from '../../lib/keycodes'

import { ReleaseDataQuality } from '../data-quality'

import css from './styles.css'

type Props = ReleaseActionsFragment & { title: string }

export function ReleaseActions(props: Props): ReactElement {
    const { discogsId, masterRelease, dataQuality, visibility, title } = props

    function sanatizeTitle(str: string) {
        return encodeURIComponent(
            str
                // Remove any non-alphanumeric characters except spaces and hyphens
                .replace(/[^a-zA-Z0-9\s-]/g, '')
                // Replace any spaces (or groups of spaces) with hyphens
                .replace(/\s+/g, '-')
                // Remove any leading or trailing hyphens
                .replace(/^-+|-+$/g, ''),
        )
    }

    const interact = useInteraction()

    useNavShortcut([g, e], <Trans>Go To Edit</Trans>, `/release/${discogsId}-${sanatizeTitle(title)}/history#latest`)

    function handleEditClick(): void {
        interact('Edit Item')
    }

    const header = (
        <>
            <h2>
                <Trans>Release</Trans>
            </h2>
            <span className={css.spacer} />
            <ShortCode type={IdentifierType.Release} discogsId={discogsId} />
        </>
    )

    return (
        <Section id='release-actions' header={header} collapsible={false}>
            <Link href={`/release/${discogsId}-${sanatizeTitle(title)}/history#latest`} onClick={handleEditClick}>
                <Trans>Edit Release</Trans>
            </Link>
            <br />
            {masterRelease && (
                <>
                    <Link href={masterRelease.siteUrl}>
                        <Trans>See all versions</Trans>
                    </Link>
                    <br />
                </>
            )}
            <ReleaseDataQuality dataQuality={dataQuality} visibility={visibility} />
        </Section>
    )
}
