import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'

import { Header } from '../../lib/components/header'
import { HeaderTable, Item } from '../../lib/components/header-table'
import { intersperse } from '../../lib/intersperse'
import { TruncatedDate } from '../../lib/components/date'
import { Link } from '@mui/material'
import { year, decade } from '../../lib/date'

import { ReleaseImages } from '../release-images'
import { ReleaseArtistLink } from '../release-artist-link'
import { ReleaseLabelLink } from '../release-label-link'
import { ReleaseFormatLink } from '../release-format-link'
import { ReleaseSeriesLink } from '../release-series-link'

import { ReleaseHeaderFragment, LabelRelationship, ReleaseLabelRole } from '../../api/types'

export type Props = ReleaseHeaderFragment

function onlyLabel(label: LabelRelationship): boolean {
    return label.labelRole === ReleaseLabelRole.Label
}

function onlySeries(label: LabelRelationship): boolean {
    return label.labelRole === ReleaseLabelRole.Series
}

export function ReleaseHeader(props: Props): ReactElement {
    const { primaryArtists = [], country, formats = [], genres = [], labels = [], styles = [], title, released } = props

    const headerLabels = labels.filter(onlyLabel)
    const series = labels.filter(onlySeries)

    const htitle = (
        <>
            <ReleaseArtistLink artists={primaryArtists} /> &ndash; {title}
        </>
    )

    return (
        <Header images={<ReleaseImages {...props} itemType='RELEASE' />} title={htitle}>
            <HeaderTable>
                <Item name={<Trans>Label</Trans>}>
                    <ReleaseLabelLink labels={headerLabels} />
                </Item>
                {series.length > 0 && (
                    <Item name={<Trans>Series</Trans>}>
                        <ReleaseSeriesLink series={series} />
                    </Item>
                )}
                <Item name={<Trans>Format</Trans>}>
                    <ReleaseFormatLink formats={formats} />
                </Item>
                <Item name={<Trans>Country</Trans>}>
                    {country && (
                        <Link variant='labelSmall' href={`/search/?country=${country}`}>
                            {country}
                        </Link>
                    )}
                </Item>
                <Item name={<Trans>Released</Trans>}>
                    {released && (
                        <Link variant='labelSmall' href={`/search/?decade=${decade(released)}&year=${year(released)}`}>
                            <TruncatedDate value={released} />
                        </Link>
                    )}
                </Item>
                <Item name={<Trans>Genre</Trans>}>
                    {intersperse(
                        ', ',
                        genres.map((genre: string) => (
                            <Link variant='labelSmall' key={genre} href={`/genre/${genre.toLowerCase()}`}>
                                {genre}
                            </Link>
                        )),
                    )}
                </Item>
                <Item name={<Trans>Style</Trans>}>
                    {intersperse(
                        ', ',
                        styles.map((style: string) => (
                            <Link variant='labelSmall' key={style} href={`/style/${style.toLowerCase()}`}>
                                {style}
                            </Link>
                        )),
                    )}
                </Item>
            </HeaderTable>
        </Header>
    )
}
