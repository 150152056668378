import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'

import { UserCollectionWantlistInfoFragment, UserCollectionSettingsFragment } from '../../api/types'
import { login, useLogin } from '../../lib/login'
import { Button } from '@discogs/amped/button'
import classnames from 'classnames'
import { useShortcut } from '../../lib/components/shortcuts'
import { Collection, Eye, Minus } from '../../lib/components/icon'
import { Spinner } from '../../lib/components/spinner'
import { useInteraction } from '../../lib/components/analytics'
import { a, c, w } from '../../lib/keycodes'

import { CollectionItemUI } from './collection-item'
import { WantlistItemUI } from './wantlist-item'

import { useAddReleaseToCollectionMutation } from '../../mutations/add-release-to-collection'
import { useAddReleasesToWantlistMutation } from '../../mutations/add-releases-to-wantlist'
import { useRemoveReleasesFromWantlistMutation } from '../../mutations/remove-releases-from-wantlist'

import css from './styles.css'

type CollectionItemEdge = NonNullable<UserCollectionWantlistInfoFragment['collectionItems']>['edges'][number]

type Props = {
    discogsId: number
    info: UserCollectionWantlistInfoFragment | null | undefined
    user: UserCollectionSettingsFragment | null | undefined
    loading: boolean
}

export function ReleaseCollectionActions(props: Props): ReactElement {
    const { loggedIn } = useLogin()
    const { discogsId, info, user, loading } = props

    const addc = useAddReleaseToCollectionMutation()
    const addw = useAddReleasesToWantlistMutation()
    const removew = useRemoveReleasesFromWantlistMutation()
    const track = useInteraction()

    function addToCollection(): void {
        if (!loggedIn) {
            login()
            return
        }
        track('Add to Collection')
        void addc.perform({ discogsId })
    }

    function addToWantlist(): void {
        if (!loggedIn) {
            login()
            return
        }
        track('Add to Wantlist')
        void addw.perform({ discogsIds: [discogsId] })
    }

    function removeFromWantlist(): void {
        if (!loggedIn) {
            login()
            return
        }
        void removew.perform({ discogsIds: [discogsId] })
    }

    useShortcut([a, c], 'action', <Trans>Add To Collection</Trans>, addToCollection, [login])
    useShortcut([a, w], 'action', <Trans>Add To Wantlist</Trans>, addToWantlist, [login])

    return (
        <div className={css.wrapper}>
            <div className={css.buttons}>
                <Button
                    variant='secondary'
                    dense
                    className={css.button}
                    onClick={addToCollection}
                    disabled={loading || addc.loading}
                >
                    {addc.loading && <Spinner />}
                    {!addc.loading && (
                        <>
                            <Collection aria-hidden='true' /> <Trans>Add to Collection</Trans>
                        </>
                    )}
                </Button>
                {info?.inWantlist ? (
                    <Button
                        variant='secondary'
                        dense
                        className={css.button}
                        onClick={removeFromWantlist}
                        disabled={loading || removew.loading}
                    >
                        {removew.loading && <Spinner />}
                        {!removew.loading && (
                            <>
                                <Minus aria-hidden='true' /> <Trans>Remove from Wantlist</Trans>
                            </>
                        )}
                    </Button>
                ) : (
                    <Button
                        variant='secondary'
                        dense
                        className={classnames(css.button, 'ld-add-to-wantlist')}
                        onClick={addToWantlist}
                        disabled={loading || addw.loading}
                    >
                        {addw.loading && <Spinner />}
                        {!addw.loading && (
                            <>
                                <Eye aria-hidden='true' /> <Trans>Add to Wantlist</Trans>
                            </>
                        )}
                    </Button>
                )}
            </div>
            {user &&
                info?.collectionItems?.edges.map((edge: CollectionItemEdge): ReactElement | null =>
                    edge.node ? (
                        <CollectionItemUI
                            discogsId={discogsId}
                            item={edge.node}
                            key={edge.node.discogsId}
                            user={user}
                        />
                    ) : null,
                )}
            {info?.inWantlist && (
                <WantlistItemUI
                    discogsId={discogsId}
                    item={info.inWantlist}
                    remove={removeFromWantlist}
                    removing={removew.loading}
                />
            )}
        </div>
    )
}
