import { ReactElement } from 'react'
import { Meta, Link } from 'react-head'
import { t } from '@lingui/macro'
import { artists } from '../seo-schema/schema'
import { ReleaseMetaTagsFragment, ReleaseVisibility } from '../../api/types'
import { useLocalisation } from '../../lib/i18n'
import { year } from '../../lib/date'
import { releaseTitle } from '../release-title'
import { canonicalLink } from '../../lib/canonical-link'

export type Props = ReleaseMetaTagsFragment & {
    head?: React.ComponentType<{}>
    itemType: string
    discogsId: number
}

export function ReleaseMetaTags(props: Props): ReactElement {
    const { i18n } = useLocalisation()
    const { locale } = i18n
    const { images, siteUrl, formats, title, released, visibility, primaryArtists } = props

    const image = images.edges[0]?.node?.fullsize.sourceUrl
    const htitle = releaseTitle(props)
    const info = [released && year(released), formats?.[0]?.name]
        .filter((x: string | number | undefined): boolean => Boolean(x))
        .join(' ')

    const description = t(
        i18n,
    )`View credits, reviews, tracks and shop for the ${info} release of "${title}" on Discogs.`

    const ogTitle = `${artists(primaryArtists)}${title}`

    return (
        <>
            <Meta property='og:type' content='music.album' />
            <Meta property='og:title' content={ogTitle} />
            <Meta property='twitter:title' content={htitle} />

            <Meta name='description' content={description} />
            <Meta property='og:description' content={description} />
            <Meta property='twitter:description' content={description} />

            <Meta property='og:site_name' content='Discogs' />
            <Meta property='twitter:site' content='@discogs' />

            {image && <Meta property='og:image' content={image} />}
            {image && <Meta property='twitter:image:src' content={image} />}

            <Meta property='og:url' content={`https://www.discogs.com${siteUrl}`} />

            <Meta property='fb:app_id' content='117175028305858' />
            <Meta property='twitter:card' content='summary_large_image' />
            {canonicalLink(locale, siteUrl)}
            <Link rel='alternate' hrefLang='x-default' href={`https://www.discogs.com${siteUrl}`} />

            {visibility !== ReleaseVisibility.Public && <Meta name='robots' content='noindex' />}
        </>
    )
}
